/* eslint-disable one-var */
(function () {
  'use strict';
  /* global L, Intl, _, document */
  class MapasGridCtrl {
    constructor($scope, $filter, $interval, $location, $rootScope, $sce, $state, MapasUtil, GoogleTagManagerUtils,
                moment, Keys, ServiceVfiltro, FactoryVfiltro, ObjetosVfiltroService, StatusVeiculo,
                blockUI, VtabelaFactory, uiGridConstants, VpopupService, leafletData, $uibModal, AlertMessage,
                $httpParamSerializer, VeiculoStatusEnum, $translate, PontosReferenciaMenu, Ocorrencias, $window, Uos,
                $q, MockGetStatusVeiculo, UrlConfigService, Authenticator, LanguagesEnum, ConvertersService, FeaturehubJsSdk,
                FeatureHubConfigKeys) {
      this.httpParamSerializer = $httpParamSerializer;
      this.sce = $sce;
      this.scope = $scope;
      this.filter = $filter;
      this.location = $location;
      this.rootScope = $rootScope;
      this.intervalService = $interval;
      this.state = $state;
      this.moment = moment;
      this.mapasUtil = MapasUtil;
      this.leafletData = leafletData;
      this.statusVeiculoService = StatusVeiculo;
      this.keys = Keys;
      this.serviceVfiltro = ServiceVfiltro;
      this.objetosVfiltroService = ObjetosVfiltroService;
      this.blockUI = blockUI;
      this.vtabelaFactory = VtabelaFactory;
      this.uiGridConstants = uiGridConstants;
      this.vpopupService = VpopupService;
      this.modalService = $uibModal;
      this.alertMessage = AlertMessage;
      this.veiculoStatusEnum = VeiculoStatusEnum;
      this.translate = $translate;
      this.pontosReferenciaMenu = PontosReferenciaMenu;

      this.flagPremiumTelemetry = FeaturehubJsSdk.isFeatureEnabled(FeatureHubConfigKeys.FEATURE_PREMIUM_TELEMETRY);
      this.exibicao = true;
      this.googleTagManagerUtils = GoogleTagManagerUtils;
      this.q = $q;
      this.mockStatusVeiculo = MockGetStatusVeiculo;
      this.urlConfigService = UrlConfigService;
      this.authenticator = Authenticator;
      this.languagesEnum = LanguagesEnum;
      this.convertersService = ConvertersService;
      this.vtabela = null;
      this.showLoad = true;
      this.map = null;
      this.trusted = {};
      this.limitVeiculosEnvioComando = 100;
      this.fraseErro = `${this.translate.instant('ce.mapa.localizacaoDaFrota.fraseErro')}
         <a href="http://portal.veltec.com.br" target="_blank">
          ${this.translate.instant('ce.mapa.localizacaoDaFrota.fraseErroLink')}
         </a>`;
      this.fraseTooltip = `${this.translate.instant('ce.mapa.localizacaoDaFrota.fraseTooltip')} <strong>${this.translate.instant('ce.mapa.localizacaoDaFrota.movimento')}.</strong>`;
      this.tooltipDisabledMenuComando = this.translate.instant('ce.mapa.localizacaoDaFrota.disableTooltipMenuComando', {qtd: this.limitVeiculosEnvioComando});
      this.statusVeiculos = null;
      this.statusVeiculosTable = null;
      this.filtroPontoReferencia = null;
      this.markersCluster = null;
      this.currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.ocorrenciaService = Ocorrencias;
      this.possuiOcorrencia = {
        LABEL: this.translate.instant('ce.mapa.localizacaoDaFrota.colunas.ocorrencias'),
        SIM: this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.sim'),
        NAO: this.translate.instant('ce.mapa.localizacaoDaFrota.ocorrencia.nao')
      };
      this.semaphoreVideo = {
        LABEL: this.translate.instant('ce.mapa.localizacaoDaFrota.colunas.saudeDoVeiculo'),
        RELEASED: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.labels.liberado'),
        REQUIRES_ATTENTION: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.labels.requerAtencao'),
        EVALUATE_URGENTLY: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.labels.avaliarUrgente'),
        INDETERMINATE: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.labels.indeterminado')
      };
      this.semaphoreVideoTooltip = {
        CONNECTION: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos.conexao'),
        BEHAVIOR_EVENT: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos.eventoComportamento'),
        CAMERAS: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos.cameras'),
        VIDEO: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos.video'),
        PHOTOS: this.translate.instant('ce.mapa.localizacaoDaFrota.saudeDoVeiculoColumnInfo.tooltipInfos.fotos'),
        SDCARD: 'SD Card'
      };
      this.FIELDS_OCORR_LOC_FROTA = [
        'id',
        'veiculoId',
        'dataHora'
      ];
      this.LIVESTREAM_OPTIONS = {
        NA: 'NOT_APPLICABLE',
        VMOV_ONLINE: 'VMOV_ONLINE',
        VMOV_OFFLINE: 'VMOV_OFFLINE'
      };
      this.window = $window;
      this.isDemo = this.location.search().demo;
      Uos.isDemo = this.isDemo;
      this.exibicao = !this.isDemo;
      this.verifyMapLayers();

      this.leafletData.unresolveMap('mapaPrincipal');
      this.setMapConfig();
      this.setMapEvents();
      this.leafletData.getMap('mapaPrincipal').then(map => {
        ServiceVfiltro.init(FactoryVfiltro.get([
          {
            name: 'uoId',
            key: this.keys.uo.name
          },
          {
            name: 'uoParceiraId',
            key: this.keys.uoParceira.name,
            size: 6
          },
          {
            name: 'veiculosId',
            key: this.keys.veiculos.name,
            onlyStatus: ['LIBERADO', 'EM TESTE'],
            size: 6
          }
        ]))
        .then(vfiltro => {
          this.vfiltro = vfiltro;
          this.map = map;
          this.uoId = this.serviceVfiltro.factoryVfiltro.filtroUrl.uo;
          this.permissaoComandoEditar = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('COMANDO_EDITAR');
          this.permissaoComandoVisualizar = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('COMANDO_VISUALIZAR');
          this.permissaoOcorrenciasVisualizar = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('OCORRENCIA_VISUALIZAR');
          this.permissaoLivestreamVisualizar = ServiceVfiltro.factoryVfiltro.authenticator.hasRole('VPLAYER_LIVESTREAM_VISUALIZAR');
          this.scope.$broadcast('initPontoReferencia', map);

          this.init();
          this.setMomentLocale();
          this.vtabela = this.vtabelaFactory.get(this.initVtabela(), this.serviceVfiltro.factoryVfiltro.user, 'mapasGrid', 'v2');
          this.scope.$on('onRegisterApi', () => {
            this.stateVtabelaControl();
            this.scope.selectAllVtabela = this.vtabela.gridApi.grid.selection.selectAll;
          });
        })
        .catch(() => {
          this.leafletData.unresolveMap('mapaPrincipal');
        });
      });

      this.scope.$on('$destroy', () => {
        this.leafletData.unresolveMap('mapaPrincipal');
      });
      this.rootScope.$on('openAdvisorList', () => {
        this.exibicao = false;
      });

      this.authenticator.getUser().then(user => {
        this.userMeasurementUnits = user.measurementUnits;
      });
    }

    toggleShowGrid() {
      this.exibicao = !this.exibicao;
    }

    init() {
      const idPontoUrl = this.location.search().ponto;
      if (idPontoUrl) {
        this.filtroPontoReferencia = idPontoUrl;
      }

      if (!this.isUsuarioVeltec(this.serviceVfiltro.factoryVfiltro.user)) {
        this.loadStatusVeiculo();
      }
    }

    getClusterPointSize(numeroDeElementos) {
      if (numeroDeElementos < 10) {
        return 45;
      }
      if (numeroDeElementos < 100) {
        return 50;
      }
      return 55;
    }

    getClusterLabelSize(numeroDeElementos) {
      if (numeroDeElementos < 10) {
        return 'small';
      }
      if (numeroDeElementos < 100) {
        return 'medium';
      }
      return 'large';
    }

    temStatus() {
      for (let i = 0; i < this.statusVeiculosTable.length; i++) {
        if (angular.isDefined(this.statusVeiculosTable[i].status)) {
          return true;
        }
      }
      return false;
    }

    setMapConfig() {
      this.tiles = this.mapasUtil.tiles.veltec;
      this.defaults = this.mapasUtil.defaults;
      this.center = this.mapasUtil.getMapCenterByTimezone(this.currentTimezone);
      this.markers = {};
      this.geoJson = {};
    }

    setMapEvents() {
      angular.extend(this.scope, {
        events: {
          map: {
            enable: ['click', 'resize'],
            logic: 'emit'
          },
          markers: {
            enable: ['click'],
            logic: 'emit'
          }
        }
      });
    }

    loadStatusVeiculo(timer) {
      if (this.showLoad) {
        this.blockUI.start();
      }
      this.getStatusVeiculo().then(data => {
        const itens = data.map(item => angular.extend(item, {pontoRefProx: this.setPontoReferencia(item.pontoProximo)}));
        if (this.showLoad) {
          this.blockUI.stop();
        }
        this.statusVeiculosTable = itens;
        this.statusVeiculos = [].concat(itens);
        if ((this.showLoad || !timer) && !this.isDemo) {
          this.setCenter();
          this.setTimer();
          this.showLoad = false;
        }
        if (this.permissaoOcorrenciasVisualizar && !this.isDemo) {
          this.ocorrenciaService.getOcorrenciasNovaLocFrota(this.serviceVfiltro.getFiltroBackendNovo(this.vfiltro), this.FIELDS_OCORR_LOC_FROTA).then(ocorrenciasNovas => {
            this.addOcorrenciaNovaPropertiesToTableItem(itens, ocorrenciasNovas.data);
            this.setInfoTable(itens);
            this.markers = {};
            this.setMarkers();
          });
        } else {
          this.setInfoTable(itens);
          this.markers = {};
          this.setMarkers();
        }
      })
      .catch(() => {
        this.state.go('unprotected.errorPage', {
          lastPage: this.window.location.pathname
        });
      });
    }

    getStatusVeiculo() {
      if (this.isDemo) {
        return this.q(resolve => resolve(this.mockStatusVeiculo));
      }
      const params = _.cloneDeep(this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar));
      params.forcaObjetoReduzido = false;
      return this.statusVeiculoService.one().get(params);
    }

    addOcorrenciaNovaPropertiesToTableItem(data, ocorrenciasNovas) {
      data.forEach(tableItem => {
        tableItem.ocorrenciasNovasDoVeiculo = ocorrenciasNovas.filter(ocorrencia => ocorrencia.veiculoId === tableItem.vei);
        tableItem.veiculoHasOcorrenciaNova = this.veiculoHasOcorrenciaNova(tableItem.ocorrenciasNovasDoVeiculo, tableItem.vei);
        tableItem.veiculoOcorrenciaNovaMaisRecente = this.getVeiculoOcorrenciaNovaMaisRecente(tableItem.ocorrenciasNovasDoVeiculo);
      });
    }

    getVeiculoOcorrenciaNovaMaisRecente(ocorrenciasNovasDoVeiculo) {
      if (ocorrenciasNovasDoVeiculo.length > 1) {
        const ocorrenciaNovaMaisRecente = ocorrenciasNovasDoVeiculo.reduce((acc, atual) => new Date(acc.dataHora) > new Date(atual.dataHora) ? acc : atual);
        return ocorrenciaNovaMaisRecente;
      }
      return ocorrenciasNovasDoVeiculo[0];
    }

    veiculoHasOcorrenciaNova(ocorrenciasNovasDoVeiculo, veiculoId) {
      return ocorrenciasNovasDoVeiculo.some(ocorrenciaNova => ocorrenciaNova.veiculoId === veiculoId);
    }

    setInfoTable(data) {
      this.vtabela.setData(data.map(i => this.getItemVtabela(i)));
    }

    stateVtabelaControl() {
      this.vtabela.gridApi.core.on.rowsRendered(null, () => {
        this.selecionaRows();
        this.setSelectAll();
      });

      this.vtabela.gridApi.selection.on.rowFocusChanged(null, () => {
        this.getSelectAll();
      });

      this.vtabela.gridApi.selection.on.rowSelectionChangedBatch(null, () => {
        this.getSelectAll();
      });

      this.vtabela.gridApi.selection.on.rowSelectionChanged(null, () => {
        this.getSelectAll();
      });
    }

    selecionaRows() {
      this.vtabela.gridApi.grid.rows.map(row => {
        if (this.vtabela.rowsSelected
              .map(rowSelect => rowSelect.veiculoId)
              .includes(row.entity.veiculoId)) {
          row.setSelected(true);
        }
      });
    }

    getSelectAll() {
      this.vtabela.lastSelectAll = angular.copy(this.vtabela.gridApi.grid.selection.selectAll);
    }

    navegaHistComando(veiculoIds) {
      this.location.url(`/relatorios/historico-comando?vfiltro=dateTime:hoje;uo:${this.uoId};veiculos:${veiculoIds}`);
    }

    goHistComandos() {
      if (this.vtabela.rowsSelected.length <= 100) {
        let veiculoIds = this.vtabela.rowsSelected.map(row => row.veiculoId).join();
        this.navegaHistComando(veiculoIds);
      } else {
        this.openModalMaxSelected();
      }
    }

    openModalMaxSelected() {
      this.modalService.open({
        animation: true,
        templateUrl: 'mapas-grid/modal-select/modal-select.tpl.html',
        windowTopClass: 'modal-localizacao-frota',
        controller: 'ModalSelectCtrl',
        controllerAs: '$ctrl'
      })
      .result
      .then(() => {
        let veiculoIds = this.vtabela.rowsSelected
          .slice(0, 100)
          .map(row => row.veiculoId)
          .join();
        this.navegaHistComando(veiculoIds);
      });
    }

    setSelectAll() {
      this.vtabela.gridApi.grid.selection.selectAll = angular.copy(this.vtabela.lastSelectAll);
    }

    handleSpeedCellFormater(value) {
      const distanceUnit = this.userMeasurementUnits.distanceMeasurement;

      if (distanceUnit === 'MILES') {
        const convertedValue = this.convertersService.handleKmToMiles(value);
        return `${convertedValue} mph`;
      }
      return `${value} Km/h`;
    }

    handleOdometerLabel() {
      const distanceUnit =
        this.userMeasurementUnits
          .distanceMeasurement;

      return `decimalWithLabel:${distanceUnit === 'MILES' ? `'mi'` : `'Km'`}`;
    }

    handledateTimeCellFormater() {
      const dateTimeFormat =
        this.userMeasurementUnits.dateTimeFormat;

      return `date:\'${dateTimeFormat === 'AMERICAN' ? 'MM/dd/yyyy HH:mm:ss' : 'dd/MM/yyyy HH:mm:ss'}\'`;
    }

    initVtabela() {
      let objVtabela = {
        appScopeProvider: this,
        exportsCsv: true,
        columnDefs: [
            {
              name: 'vehicleId',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.vehicleId',
              filterCellFiltered: true,
              enableHiding: true,
              visible: false
            },
            {
              name: 'uoNome',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.uo',
              type: 'string',
              filterCellFiltered: true,
              enableHiding: false
            },
            {
              name: 'grupoNome',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.grupoDeVeiculo',
              type: 'string',
              filterCellFiltered: true
            },
            {
              name: 'motNome',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.motorista',
              type: 'string',
              cellFilter: 'withLabelToUndefined:\'ce.common.motorista.semIdentificacao\' | translate | splitComposedLabels:true',
              filterCellFiltered: true,
              headerCellFilter: 'translate',
              cellTemplate: `
                  <div class='ui-grid-cell-contents'>
                    <motorista-link
                      motorista-id='row.entity.motId'
                      motorista='row.entity.motNome'
                      has-permission='row.entity.hasPermissionDriver'
                      index='$index'>
                    </motorista-link>
                  </div>
                `
            },
            {
              name: 'veiculo',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.veiculo',
              enableHiding: false,
              filterCellFiltered: true,
              cellTemplate: `
              <div class='ui-grid-cell-contents' title="{{row.entity.veiculo}}">
                <a class="cell-veiculo" ng-click='grid.appScope.setFocus(row.entity.placa)'>
                  {{row.entity.veiculo}}
                </a>
                <span ng-if='!grid.appScope.roleVisualizarBdv || !row.entity.veiculos || !row.entity.linkBdv'> {{row.entity.veiculos}} </span>
              </div>
              `
            },
            {
              name: 'status',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.status',
              cellClass: 'text-center',
              filterCellFiltered: true,
              width: 115,
              cellTemplate: `
              <div class='ui-grid-cell-contents'>
                <span title="{{ row.entity.status | translate }}" class="icone-esquerda">
                  {{ row.entity.status | translate }}
                </span>
                <span
                  class="fa fa-info-circle text-blue margin-right-info"
                  ng-if='grid.appScope.verificaMovimentoVelocidadeZero(row.entity)'
                  uib-tooltip-html="grid.appScope.setPopoverContent(grid.appScope.fraseTooltip)"
                  tooltip-placement="top">
                </span>
                <span
                  class="fa fa-wrench text-blue"
                  ng-if="grid.appScope.isInTest(row.entity)"
                  uib-tooltip-html='grid.appScope.setPopoverContent("${this.translate.instant('ce.mapa.localizacaoDaFrota.veiculoEmManuntencao')}")'
                  tooltip-placement="top">
                </span>
              </div>
              `
            },
            {
              name: 'vel',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.velocidade',
              cellTemplate: `
                <div class="ui-grid-cell-contents">
                  {{grid.appScope.handleSpeedCellFormater(row.entity.vel)}}
                </div>
              `,
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'hodo',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.hodometro',
              cellClass: 'text-center',
              filterCellFiltered: true,
              cellTemplate: `
              <div class='ui-grid-cell-contents'>
                <span title="{{row.entity.hodo | ${this.handleOdometerLabel()} }}" class="icone-esquerda">{{row.entity.hodo | ${this.handleOdometerLabel()} }}</span>
                <span
                  class="fa fa-info-circle text-blue"
                  ng-if='grid.appScope.isRedeSat(row.entity)'
                  uib-tooltip-html='grid.appScope.setPopoverContent("${this.translate.instant('ce.mapa.localizacaoDaFrota.tooltipHodometro')}")'
                  tooltip-placement="top">
                </span>
              </div>
              `
            },
            {
              name: 'data',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.dataHora',
              cellFilter: this.handledateTimeCellFormater(),
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'bloqueioPartida',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.bloqueio',
              cellClass: 'text-center',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: true, label: this.translate.instant('ce.mapa.localizacaoDaFrota.bloqueado')},
                  {value: false, label: this.translate.instant('ce.mapa.localizacaoDaFrota.desbloqueado')}
                ]
              },
              width: 100,
              cellTemplate: `
              <div class="ui-grid-cell-contents">
                <span
                  class="fv trimble-locked red icon-bloqueio"
                  title="{{ 'ce.mapa.localizacaoDaFrota.bloqueado' | translate }}"
                  ng-if='row.entity.bloqueioPartida'>
                </span>
                <span
                 class="fv trimble-unlocked green icon-bloqueio"
                 title="{{ 'ce.mapa.localizacaoDaFrota.desbloqueado' | translate }}"
                 ng-if='row.entity.bloqueioPartida === false'>
                </span>
                <span ng-if='row.entity.bloqueioPartida === undefined'>
                  {{ 'ce.mapa.localizacaoDaFrota.undefined' | translate }}
                </span>
              </div>
              `
            },
            {
              name: 'gsmSearch',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.gsm',
              cellClass: 'text-center',
              type: 'string',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: 'ON', label: this.translate.instant('ce.mapa.common.gsmOnline')},
                  {value: 'OFF', label: this.translate.instant('ce.mapa.common.gsmOffline')},
                  {value: 'SAT', label: this.translate.instant('ce.mapa.common.gsmSatelital')}
                ]
              },
              width: 100,
              cellTemplate: `
              <div class="ui-grid-cell-contents">
                <span class="{{grid.appScope.getRede(row.entity).style}}"
                  aria-hidden='true'
                  title='{{ grid.appScope.setPopoverContent(grid.appScope.getRede(row.entity).label) | translate }}'>
                </span>
              </div>
              `
            },
            {
              name: 'statusGps',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.precisao',
              cellClass: 'text-center',
              type: 'boolean',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                {value: true, label: this.translate.instant('ce.mapa.localizacaoDaFrota.gpsAltaPrecisao')},
                {value: false, label: this.translate.instant('ce.mapa.localizacaoDaFrota.gpsBaixaPrecisao')}
                ]
              },
              width: 100,
              cellTemplate: `
              <div class="ui-grid-cell-contents">
                <span
                  ng-if='!row.entity.statusGps'
                  class="fv trimble-help v3g-text-alt-red circulo-precisao-red"
                  aria-hidden='true'
                  uib-popover-html="grid.appScope.setPopoverContent(grid.appScope.fraseErro)"
                  popover-title="{{ 'ce.mapa.localizacaoDaFrota.gpsBaixaPrecisao' | translate }}"
                  popover-trigger="'focus'"
                  popover-placement="top"
                  tabindex="0"
                  title="{{ 'ce.mapa.localizacaoDaFrota.gpsBaixaPrecisao' | translate }}">
                </span>
                <span
                  ng-if='row.entity.statusGps'
                  class="circulo-precisao-blue"
                  title="{{ 'ce.mapa.localizacaoDaFrota.gpsAltaPrecisao' | translate }}">
                </span>
              </div>
              `
            },
            {
              name: 'videoSemaphoreStatus',
              field: 'videoSemaphoreStatus.id',
              displayName: this.semaphoreVideo.LABEL,
              type: 'string',
              cellClass: 'text-left',
              filterCellFiltered: true,
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: 'RELEASED', label: this.semaphoreVideo.RELEASED},
                  {value: 'REQUIRES_ATTENTION', label: this.semaphoreVideo.REQUIRES_ATTENTION},
                  {value: 'EVALUATE_URGENTLY', label: this.semaphoreVideo.EVALUATE_URGENTLY},
                  {value: 'INDETERMINATE', label: this.semaphoreVideo.INDETERMINATE}
                ]
              },
              width: 160,
              cellTemplate: `
              <div class="ui-grid-cell-contents vehicle-health-field">
                <span
                  class="underline"
                  ng-if="row.entity.videoSemaphoreStatus.id !== 'INDETERMINATE'"
                  uib-tooltip-html='grid.appScope.getVideoEventsTooltip(row.entity.videoVitalSignsEvents, row.entity.hasPremiumTelemetry)'
                  tooltip-class="tooltip-health-vehicle"
                  tooltip-placement="right"
                  >
                  <div>
                    <p>
                      <span class="{{row.entity.videoSemaphoreStatus.style}}"></span>
                      <a ng-click='grid.appScope.setFocus(row.entity.placa, true)'>
                        {{row.entity.videoSemaphoreStatus.label}}
                      </a>
                    </p>
                  </div>
                </span>
                <span class="stream-text-color" ng-if="row.entity.videoSemaphoreStatus.id === 'INDETERMINATE'">
                  <div>
                    <span class="{{row.entity.videoSemaphoreStatus.style}}"></span>
                    {{row.entity.videoSemaphoreStatus.label}}
                  </div>
                </span>
              </div>
              `
            },
            {
              name: 'logra',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.logradouro',
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'local',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.localizacao',
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'pontoRefProx',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.pontoDeRef',
              cellClass: 'text-center',
              filterCellFiltered: true
            },
            {
              name: 'latLng',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.latLong',
              filterCellFiltered: true,
              visible: false
            },
            {
              name: 'motApel',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.apelido',
              filterCellFiltered: true
            },
            {
              name: 'motTel',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.telefoneMotorista',
              filterCellFiltered: true
            }
        ]};

      if (this.permissaoOcorrenciasVisualizar) {
        const colunaOcorrencia = {
              name: 'possuiOcorrencia',
              displayName: this.possuiOcorrencia.LABEL,
              type: 'boolean',
              cellClass: 'text-center',
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.EXACT,
                selectOptions: [
                  {value: true, label: this.possuiOcorrencia.SIM},
                  {value: false, label: this.possuiOcorrencia.NAO}
                ]
              },
              cellTemplate: `
              <span
                ng-if='row.entity.possuiOcorrencia'
                class="vertical-correction underline">
                <div>
                  <a ng-click='grid.appScope.goToQuadroOcorrenciasWithLocalStorageSet(row.entity.veiculoOcorrenciaNovaMaisRecente.id)'>
                    ${this.possuiOcorrencia.SIM}
                  </a>
                </div>
              </span>
              <span
                ng-if='!row.entity.possuiOcorrencia'
                class="vertical-correction">
                <div>${this.possuiOcorrencia.NAO}</div>
              </span>
              `
            },
            indiceSituacaoVeiculo = 13;
        objVtabela.columnDefs.splice(indiceSituacaoVeiculo, 0, colunaOcorrencia);
      }

      if (this.permissaoLivestreamVisualizar) {
        const colunaLivestream = {
              name: 'livestream',
              displayName: 'ce.mapa.localizacaoDaFrota.colunas.cameraAoVivo',
              type: 'string',
              cellClass: 'text-left',
              filterCellFiltered: true,
              filter: {
                type: this.uiGridConstants.filter.SELECT,
                condition: this.uiGridConstants.filter.STARTS_WITH,
                selectOptions: [
                  {value: this.LIVESTREAM_OPTIONS.VMOV_ONLINE, label: this.translate.instant('ce.mapa.localizacaoDaFrota.livestream.aoVivo')},
                  {value: this.LIVESTREAM_OPTIONS.VMOV_OFFLINE, label: this.translate.instant('ce.mapa.localizacaoDaFrota.livestream.offline')},
                  {value: this.LIVESTREAM_OPTIONS.NA, label: this.translate.instant('ce.mapa.localizacaoDaFrota.undefined')}
                ]
              },
              width: 125,
              cellTemplate: `
              <div class="ui-grid-cell-contents">
                <span ng-if='row.entity.livestream === grid.appScope.LIVESTREAM_OPTIONS.NA'
                  class='stream-text-color'>
                  <span class="dot-stream"></span> {{ 'ce.mapa.localizacaoDaFrota.undefined' | translate }}
                </span>
                <span
                  ng-if='row.entity.livestream === grid.appScope.LIVESTREAM_OPTIONS.VMOV_OFFLINE'
                  class='stream-text-color'
                  title="{{ row.entity.vmovDate | date:\'dd/MM/yyyy HH:mm:ss\' }}"
                >
                  <span class="dot-stream"></span> {{ grid.appScope.getLastOnlineText(row.entity.vmovDate) }}
                </span>
                <span
                  ng-if='row.entity.livestream === grid.appScope.LIVESTREAM_OPTIONS.VMOV_ONLINE && (!grid.appScope.flagPremiumTelemetry || !row.entity.hasPremiumTelemetry)'
                  class="underline"
                >
                  <div>
                    <a ng-click='grid.appScope.openDialogVehicleHealth(row.entity.posicao, 2)'>
                      <span class="dot-stream online"></span> {{ 'ce.mapa.common.vpopupService.assistirTempoReal' | translate }}
                    </a>
                  </div>
                </span>
              </div>
              `
            },
            indiceStatusGps = 12;
        objVtabela.columnDefs.splice(indiceStatusGps, 0, colunaLivestream);
      }
      return objVtabela;
    }

    getParams() {
      return this.serviceVfiltro.getFiltroBackendNovo(this.serviceVfiltro.filtroTopbar);
    }

    getUrlCsv() {
      return `/sensor/status-veiculo/csv?${this.httpParamSerializer(angular.extend(
        this.getParams(),
        {timezone: this.currentTimezone}
      ))}`;
    }

    getItemVtabela(item) {
      const videoSemaphoreStatusConfig = this.getVideoSemaphoreStatusConfig(item.videoVitalSigns);
      if (item.videoVitalSigns) {
        item.videoVitalSigns.videoSemaphoreStatusConfig = videoSemaphoreStatusConfig;
      }

      return {
        vehicleId: item.vei,
        uoNome: item.uoNome,
        grupoNome: item.grupoNome,
        motId: item.motoristaId,
        motNome: item.motNome,
        motApel: item.motApel,
        motTel: item.motTel,
        veiculo: this.buildVeiculo(item.prefixo, item.placa),
        veiculoId: item.vei,
        status: this.getVeiculoStatusi18n(item.status),
        vel: item.vel,
        hodo: item.hodo,
        data: item.data,
        bloqueioPartida: item.bloqueioPartida,
        gsm: item.gsm,
        gsmSearch: this.getRede(item).id,
        statusGps: item.statusGps,
        logra: item.logra,
        local: item.local,
        pontoRefProx: item.pontoRefProx,
        placa: item.placa,
        meio: item.meio,
        situacaoVeiculo: item.situacaoVeiculo,
        latLng: item.lat + ', ' + item.lng,
        possuiOcorrencia: item.veiculoHasOcorrenciaNova,
        veiculoOcorrenciaNovaMaisRecente: item.veiculoOcorrenciaNovaMaisRecente,
        livestream: this.getLivestream(item),
        vmovDate: item.vmovDate,
        posicao: item.hasStream && item.vmovOnline ? item : null,
        videoSemaphoreStatus: videoSemaphoreStatusConfig,
        videoVitalSignsEvents: item.videoVitalSigns,
        hasPremiumTelemetry: item.hasPremiumTelemetry,
        hasPermissionDriver: item.hasPermissaoMotorista
      };
    }

    tooltipMsg() {
      if (this.permissaoComandoEditar || this.permissaoComandoVisualizar) {
        return this.translate.instant('ce.mapa.localizacaoDaFrota.maisOpcoesTooltip');
      }
      return this.translate.instant('ce.mapa.localizacaoDaFrota.semPermissaoVeiculo');
    }

    isMenuHabilitado() {
      return (this.permissaoComandoEditar || this.permissaoComandoVisualizar) &&
        this.vtabela.rowsSelected.length;
    }

    modalEnviarComando() {
      let veiculoIds = this.vtabela.rowsSelected.map(row => row.veiculoId);
      if (veiculoIds.length <= this.limitVeiculosEnvioComando) {
        this.modalService.open({
          animation: true,
          templateUrl: 'mapas-grid/envio-comando/envio-comando.tpl.html',
          controller: 'EnviarComandoCtrl',
          controllerAs: '$ctrl',
          windowTopClass: 'modal-localizacao-frota',
          resolve: {
            veiculoIds: () => veiculoIds
          }
        }).result
        .then(data => {
          let alert = {
            message: data.msg,
            appendTo: '.alerta-aqui'
          };
          alert.type = data.hasAfetados ? 'success' : 'warning';
          this.alertMessage.create(alert);
        });
      }
    }

    podeEnviarComando() {
      return this.vtabela.rowsSelected.length <= this.limitVeiculosEnvioComando;
    }

    buildVeiculo(prefixo, placa) {
      if (angular.isDefined(prefixo) && angular.isDefined(placa)) {
        return prefixo + ' - ' + placa;
      }
      return placa;
    }

    setTimer() {
      if (angular.isDefined(this.rootScope.timerMapa)) {
        this.intervalService.cancel(this.rootScope.timerMapa);
      }
      this.rootScope.timerMapa = this.intervalService(() => {
        this.loadStatusVeiculo(true);
      }, 60000);
    }

    setMarkers() {
      if (!this.markersCluster) {
        this.markersCluster = L.markerClusterGroup({
          spiderfyDistanceMultiplier: 3,
          iconCreateFunction: cluster => {
            let childCount = cluster.getChildCount(), size = this.getClusterPointSize(childCount), qtdTotalOcorrenciasNovasAgrupamento = 0;

            if (this.permissaoOcorrenciasVisualizar) {
              const clusterChildren = cluster.getAllChildMarkers();
              clusterChildren.forEach(clusterChild => {
                qtdTotalOcorrenciasNovasAgrupamento += clusterChild.options.posicao.ocorrenciasNovasDoVeiculo.length;
              });
            }

            return new L.DivIcon({
              html: `<div>
                      <span>${childCount}</span>
                      <span class="quantity-container ${qtdTotalOcorrenciasNovasAgrupamento > 0 ? '' : 'no-display'}">${qtdTotalOcorrenciasNovasAgrupamento}</span>
                    </div>`,
              className: `marker-cluster marker-cluster-${this.getClusterLabelSize(childCount)}`,
              iconSize: new L.Point(size, size)});
          }
        });
      }
      this.markersCluster.clearLayers();
      this.statusVeiculos.forEach((posicao) => {
        let markerVeic = L.marker(
          [posicao.lat, posicao.lng], {
            icon: L.divIcon(this.mapasUtil.getIconByZoom(11, posicao)),
            posicao: posicao,
            vei: posicao.vei}
          );
        this.markersCluster.addLayer(markerVeic);
        this.onClickBindPopup(markerVeic);
      });
      if (!this.map.hasLayer(this.markersCluster)) {
        this.map.addLayer(this.markersCluster);
      }
    }

    onClickBindPopup(markerVeic) {
      markerVeic.on('click', marker => {
        if (angular.isUndefined(marker.target.getPopup())) {
          marker.target.bindPopup(
            this.vpopupService.getVeiculoElement(markerVeic.options.posicao),
            {className: 'vpopup'}
          );
          marker.target.openPopup();
        }
      });
    }

    getVeiculoLayer(vei) {
      let veiculo;
      this.markersCluster.getLayers().forEach(veiculoLayer => {
        if (veiculoLayer.options.vei === vei) {
          veiculo = veiculoLayer;
        }
      });
      return veiculo;
    }

    setCenter() {
      if (this.statusVeiculos.length === 1 && this.filtroPontoReferencia === null) {
        this.center = {
          lat: this.statusVeiculos[0].lat,
          lng: this.statusVeiculos[0].lng,
          zoom: 12
        };
      }
    }

    setFocus(placa, shouldOpenSemaphoreModal) {
      this.exibicao = false;
      this.statusVeiculos.filter(s => s.placa === placa).map(s => {
        let layerVeiculo = this.getVeiculoLayer(s.vei);
        this.markersCluster.zoomToShowLayer(layerVeiculo, () => {
          if (angular.isUndefined(layerVeiculo.getPopup())) {
            layerVeiculo.bindPopup(this.vpopupService.getVeiculoElement(layerVeiculo.options.posicao),
            {className: 'vpopup'});
          }

          layerVeiculo.openPopup(layerVeiculo);

          if (shouldOpenSemaphoreModal) {
            this.openDialogVehicleHealth(layerVeiculo.options.posicao, 0);
          }
        });
      });
    }

    goToQuadroOcorrenciasWithLocalStorageSet(ocorrenciaId) {
      this.googleTagManagerUtils.sendEventClickGTM('localizacao_frota_ir_para_ocorrencia', this.serviceVfiltro.factoryVfiltro.user);
      this.window.localStorage.setItem('localizacao-frota-go-to-quadro-ocorrencias-ocorrenciaId', ocorrenciaId);
      this.window.open(`/tempo-real/ocorrencias?vfiltro=uo:${this.uoId}`, '_blank');
    }

    isUsuarioVeltec(usuario) {
      return usuario.uo.id === 1;
    }

    getRede(row) {
      if (row.meio === 'SAT' && row.gsm) {
        return {
          style: 'trimble-sattelite v3g-text-blue',
          label: 'ce.mapa.common.gsmSatelital',
          id: 'SAT'
        };
      } else if (row.gsm) {
        return {
          style: 'trimble-connection-bar text-green',
          label: 'ce.mapa.common.gsmOnline',
          id: 'ON'
        };
      }
      return {
        style: 'trimble-connection-off v3g-text-alt-red',
        label: 'ce.mapa.common.gsmOffline',
        id: 'OFF'
      };
    }

    getVideoSemaphoreStatusConfig(videoVitalSigns) {
      const status = videoVitalSigns ? videoVitalSigns.videoSemaphoreStatus : 'INDETERMINATE';
      switch (status) {
        case 'RELEASED':
          return {
            style: 'dot-stream online',
            label: this.semaphoreVideo.RELEASED,
            id: 'RELEASED'
          };
        case 'REQUIRES_ATTENTION':
          return {
            style: 'dot-stream warning',
            label: this.semaphoreVideo.REQUIRES_ATTENTION,
            id: 'REQUIRES_ATTENTION'
          };
        case 'EVALUATE_URGENTLY':
          return {
            style: 'dot-stream urgent',
            label: this.semaphoreVideo.EVALUATE_URGENTLY,
            id: 'EVALUATE_URGENTLY'
          };
        default:
          return {
            style: 'dot-stream',
            label: this.translate.instant('ce.mapa.localizacaoDaFrota.undefined'),
            id: 'INDETERMINATE'
          };
      }
    }

    getVideoEventsTooltip(videoEvents, hasPremiumTelemetry) {
      const {CONNECTION, BEHAVIOR_EVENT, CAMERAS, VIDEO, PHOTOS, SDCARD} = this.semaphoreVideoTooltip,
          getFormattedLastOnline = (event) => event ? this.getLastOnlineText(event) : '-',
          tooltip = `
        <div id="vehicle-health-tooltip" class="saude-veiculo-tooltip">
          <p>${CONNECTION}: ${getFormattedLastOnline(videoEvents.lastConnection)}</p>
          <p>${BEHAVIOR_EVENT}: ${getFormattedLastOnline(videoEvents.lastBehaviorEvent)}</p>
          <p>${CAMERAS}: ${videoEvents.cameras || '0'}</p>
          ${this.flagPremiumTelemetry && hasPremiumTelemetry ? '' : `<p>${VIDEO}: ${getFormattedLastOnline(videoEvents.lastVideo)}</p>`}
          <p>${PHOTOS}: ${getFormattedLastOnline(videoEvents.lastPhoto)}</p>
          <p>${SDCARD}: ${this.vpopupService.getStorageHealth(videoEvents.storageHealth) || '-'}</p>
        </div>
      `;

      return tooltip;
    }

    isRedeSat(row) {
      return row.meio === 'SAT' && row.gsm;
    }

    isInTest(row) {
      return row.situacaoVeiculo === 'EM TESTE';
    }

    setPontoReferencia(obj) {
      let texto = '';
      if (angular.isDefined(obj)) {
        if (obj.distancia === 0) {
          texto += 'em ' + obj.pontoReferencia.nome;
        } else {
          texto += obj.distancia + 'm de ' + obj.pontoReferencia.nome;
        }
      }
      return texto;
    }

    setPopoverContent(item) {
      return this.trusted[item] || (this.trusted[item] = this.sce.trustAsHtml(item));
    }

    verificaMovimentoVelocidadeZero(item) {
      return item.status === 'Movimento' && item.vel === 0;
    }

    onLayerSwitch(layerWrapper) {
      this.tiles = layerWrapper.getLayerConfig();
    }

    getVeiculoStatusi18n(status) {
      if (!status) {
        return '';
      }
      let statusi18n = this.veiculoStatusEnum.getStatusById(status.toUpperCase());
      return statusi18n ? statusi18n.descricao : '';
    }

    verifyMapLayers() {
      if (this.location.search().forceRenderLayer) {
        this.authenticator.getUser().then((user) => {
          this.mapasUtil.activateAllMapLayers(user.id);
        });
      }
    }

    getLivestream(item) {
      if (!item.hasStream) {
        return this.LIVESTREAM_OPTIONS.NA;
      }
      return item.vmovOnline ? this.LIVESTREAM_OPTIONS.VMOV_ONLINE : this.LIVESTREAM_OPTIONS.VMOV_OFFLINE;
    }

    setMomentLocale() {
      this.moment.locale('pt-br');
      this.authenticator.getUser().then(user => {
        const momentLang = this.languagesEnum.getLanguage(user.idioma).moment;
        this.moment.locale(momentLang);
      });
    }

    getLastOnlineText(date) {
      return this.moment(date).fromNow();
    }

    openDialogVehicleHealth(posicao, initialTab) {
      if (posicao) {
        /* eslint-disable */
        const elementContainer = document.querySelector('#dialog-vehicle-health');
        const dialogOpener = document.createElement('vfwc-open-dialog-vehicle-health-evidences');
        /* eslint-enable */

        dialogOpener.usuario = this.serviceVfiltro.factoryVfiltro.user;
        dialogOpener.veiculo = posicao;
        dialogOpener.hasLivestreamPermission = this.permissaoLivestreamVisualizar && posicao.hasStream;
        dialogOpener.initialTab = initialTab || 0;

        elementContainer.appendChild(dialogOpener);
      }
      return '';
    }
  }

  /**
   * @ngdoc object
   * @name mapasGrid.controller:MapasGridCtrl
   *
   * @description
   *
   */
  angular
    .module('mapasGrid')
    .controller('MapasGridCtrl', MapasGridCtrl);
}());
